<template>
  <div>
    <van-action-sheet v-model="showAction" :title="'补库存'" class="modalclass">
      <div class="combox">
        <div class="inputbox">
          <van-field
            label-align="right"
            label-width="60"
            v-model="stocknum"
            label="商品库存"
            placeholder="请输入库存"
          />
        </div>
        <div class="btncontent">
          <div class="btn" @click="clickStore">保存</div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { addStock } from "@/request/storeapi";
import { Toast } from "vant";
export default {
  name: "EtWebAddstock",

  data() {
    return {
      showAction: false,
      goodID: "",
      stocknum: "",
    };
  },

  mounted() {},

  methods: {
    preview(goodid) {
      this.showAction = true;
      this.goodID = goodid;
    },
    requestaddStock() {
      const params = {
        good_id: this.goodID,
        stock: this.stocknum,
      };
      addStock(params).then((res) => {
        Toast(res.msg);
        let success = res.success;
        if (success) {
          this.$emit("refreshData");
          this.showAction = false;
        }
      });
    },
    clickStore() {
      if (this.stocknum.length === 0) {
        Toast("请输入库存");
        return;
      }
      this.requestaddStock();
    },
  },
};
</script>

<style lang="scss" scoped>
.modalclass {
  border-radius: 12px 0px;
  background: white;
  .combox {
    padding: 32px 32px 130px;
    .inputbox {
      width: 686px;
      height: 84px;
      border-radius: 16px;
      opacity: 1;
      border: 2px solid rgba(255, 223, 204, 1);
      background: rgba(250, 247, 245, 1);
      ::v-deep .van-cell {
        background-color: transparent;
        line-height: 20px;
      }
    }
    .btncontent {
      display: flex;
      justify-content: center;
      margin-top: 74px;
      .btn {
        width: 478px;
        height: 72px;
        border-radius: 74px;
        opacity: 1;
        background: rgba(255, 114, 32, 1);
        color: rgba(238, 238, 238, 1);
        font-size: 28px;
        font-weight: 700;
        font-family: "PingFang SC";
        text-align: center;
        line-height: 72px;
      }
    }
  }
}
</style>
