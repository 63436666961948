<template>
  <div>
    <MallheaderVue :titlename="'商品仓库'"></MallheaderVue>
    <div class="warecontent">
      <van-tabs v-model="tabvalue" @change="changeTab">
        <div v-for="tab in tabarr" :key="tab">
          <van-tab :title="tab"> </van-tab>
        </div>
      </van-tabs>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <div class="listcontent">
            <div class="item" v-for="(item, index) in list" :key="index">
              <img class="pic" :src="item.images"  @click="toGoodDetail(item.id)" />
              <div class="info">
                <div class="title van-multi-ellipsis--l2"  @click="toGoodDetail(item.id)">
                  {{ item.name }}
                </div>
                <div class="bottom"  @click="toGoodDetail(item.id)">
                  <div class="price">
                    <span class="num">{{ parseFloat(item.price).toFixed(2) }}</span>
                    <span class="unit">/ USD</span>
                  </div>
                  <div class="see">查看 ></div>
                </div>
                <div class="salesinfo">
                  <div class="inventory">
                    <div class="txt">库存：</div>
                    <div class="num">{{ item.stock }}</div>
                  </div>
                  <div class="inventory">
                    <div class="txt">已售：</div>
                    <div class="num">{{ item.buy_count }}</div>
                  </div>
                </div>
                <div class="btncell" v-if="tabvalue == 0">
                  <div class="btninventory" @click="showstock(item)">补库存</div>
                  <div class="btninventory" @click="clickLower(item, 2)">下架</div>
                </div>
                <div class="btncell" v-if="tabvalue == 1 || tabvalue == 2">
                  <div class="btninventory" @click="delgood(item)">删除</div>
                  <div class="shelves" @click="upshelves(item, 1)">上架</div>
                </div>
                <div class="btncell" v-if="tabvalue == 3">
                  <div class="btninventory" @click="delgood(item)">删除</div>
                  <div class="shelves" @click="upshelves(item, 1)">上架</div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <addstock ref="addstock" @refreshData="refreshData"></addstock>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import { goodsList, editMarketable, deleteGoods } from "@/request/storeapi";
import MallheaderVue from "../../components/mallheader.vue";
import Addstock from "./actionsheet/addstock.vue";
export default {
  components: {
    MallheaderVue,
    Addstock,
  },
  data() {
    return {
      tabvalue: 0,
      tabarr: ["出售中", "库存不足", "已下架", "待审核"],
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      currentpage: 1,
    };
  },
  activated() {
    this.onRefresh();
  },
  methods: {
      toGoodDetail(id){
          this.$router.push({
              path: "/mall/goodsdetail",
              query: {
                  id: id,
              },
          });
      },
    changeTab(value) {
      this.onRefresh();
    },
    requestgoodsList() {
      const params = {
        goods_status: this.tabvalue + 1,
        page: this.currentpage,
        limit: 20,
      };
      goodsList(params).then((res) => {
        if (this.refreshing) {
          this.refreshing = false;
        }
          this.list = [ ...res.data.list];
        if (res.data.list.length < 20) {
          this.finished = true;
        }
        this.loading = false;
      });
    },
    requestdeleteGoods(goodid) {
      deleteGoods({ good_id: goodid }).then((res) => {
        let success = res.success;
        Toast(res.msg);
        if (success) {
          this.onRefresh();
        }
      });
    },
    requesteditMarketable(goodid, flag) {
      const params = {
        good_id: goodid,
        marketable: flag,
      };
      editMarketable(params).then((res) => {
        let success = res.success;
        Toast(res.msg);
        if (success) {
          this.onRefresh();
        }
      });
    },
    delgood(item) {
      Dialog.confirm({
        title: "删除",
        message: "您正在删除该商品，是否确认？",
        confirmButtonText: "确认",
        confirmButtonColor: "#FF7220",
      })
        .then(() => {
          this.requestdeleteGoods(item.id);
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    upshelves(item, flag) {
      Dialog.confirm({
        title: "上架",
        message: "您正在上架该商品，是否确认？",
        confirmButtonText: "确认",
        confirmButtonColor: "#FF7220",
      })
        .then(() => {
          this.requesteditMarketable(item.id, flag);
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    clickLower(item, flag) {
      Dialog.confirm({
        title: "下架",
        message: "您正在下架该商品，是否确认？",
        confirmButtonText: "确认",
        confirmButtonColor: "#FF7220",
      })
        .then(() => {
          this.requesteditMarketable(item.id, flag);
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    showstock(item) {
      this.$refs.addstock.preview(item.id);
    },
    onLoad() {
      this.requestgoodsList();
        // this.currentpage++;
    },
    refreshData() {
      this.onRefresh();
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.currentpage = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
  },
};
</script>

<style lang="scss" scoped>
.warecontent {
  background: #ebeef2;
  padding: 0 24px;
  min-height: 100vh;
  ::v-deep .van-tabs__nav {
    background-color: transparent;
  }
  ::v-deep .van-tabs__line {
    width: 28px;
    height: 4px;
    border-radius: 4px;
    opacity: 1;
    background: rgba(255, 114, 32, 1);
    bottom: 20px;
  }
  .listcontent {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item {
      margin-bottom: 24px;
      width: 340px;
      min-height: 500px;
      border-radius: 24px;
      opacity: 1;
      background: rgba(255, 255, 255, 1);
      .pic {
        width: 340px;
        height: 340px;
        border-radius: 24px 24px 0 0;
        opacity: 1;
        background: orange;
      }
      .info {
        padding: 16px;
        .title {
          color: rgba(41, 33, 29, 1);
          font-size: 24px;
          font-weight: 400;
          font-family: "PingFang SC";
          text-align: left;
          line-height: 36px;
        }
        .bottom {
          margin-top: 16px;
          display: flex;
          justify-content: space-between;
          .price {
            font-size: 14px;
            .num {
              color: rgba(255, 114, 32, 1);
              font-weight: 500;
              font-family: "DIN";
              text-align: left;
              line-height: 20px;
              font-size: 14px;
            }
            .unit {
              color: rgba(255, 114, 32, 1);
              font-weight: 500;
              font-family: "DIN";
              text-align: left;
              line-height: 20px;
              font-size: 10px;
            }
          }
          .see {
            color: rgba(156, 152, 150, 1);
            font-size: 20px;
            font-weight: 400;
            font-family: "PingFang SC";
            text-align: left;
            line-height: 32px;
          }
        }
        .salesinfo {
          margin-top: 16px;
          display: flex;
          justify-content: space-between;
          .inventory {
            display: flex;
            .txt {
              color: rgba(156, 152, 150, 1);
              font-size: 24px;
              font-weight: 400;
              font-family: "PingFang SC";
              text-align: left;
              line-height: 36px;
            }
            .num {
              color: rgba(41, 33, 29, 1);
              font-size: 24px;
              font-weight: 400;
              font-family: "PingFang SC";
              text-align: left;
              line-height: 36px;
            }
          }
        }
        .btncell {
          display: flex;
          justify-content: space-between;
          .btninventory {
            margin-top: 24px;
            width: 144px;
            height: 52px;
            border-radius: 50px;
            opacity: 1;
            background: rgba(235, 238, 242, 1);
            color: rgba(105, 98, 95, 1);
            font-size: 24px;
            font-weight: 400;
            font-family: "PingFang SC";
            text-align: center;
            line-height: 52px;
          }
          .shelves {
            margin-top: 24px;
            width: 144px;
            height: 52px;
            border-radius: 50px;
            opacity: 1;
            background: rgba(255, 247, 242, 1);
            color: rgba(255, 114, 32, 1);
            font-size: 24px;
            font-weight: 400;
            font-family: "PingFang SC";
            text-align: center;
            line-height: 52px;
          }
        }
      }
    }
  }
}
</style>
